<template>
  <div>
    <!-- Media -->
    <b-card>
      <!-- User Info: Input Fields -->
      <b-form>

        <b-row>
          <b-col
            cols="12"
            class="mb-2"
          >

            <div class="border rounded p-2">
              <h4 class="mb-1">
                الصورة
              </h4>
              <b-media
                no-body
                vertical-align="center"
                class="flex-column flex-md-row"
              >
                <div class="d-inline-block">
                  <b-media-aside>
                    <b-img
                      ref="previewEl"
                      :src="profileData.profile_image"
                      height="110"
                      width="170"
                      class="rounded mr-2 mb-1 mb-md-0 col-12"
                    />
                  </b-media-aside>

                </div>
                <b-media-body>
                  <b-card-text class="mt-1" />
                  <div class="d-inline-block mt-1">
                    <b-form-group
                      label=" Image"
                      label-for="blog-tab-title"
                      class="mb-2"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Tab Image"
                      >
                        <b-form-file
                          ref="refInputEl"
                          v-model="Tabimage"
                          accept=".jpg, .png, .gif, .jpeg"
                          placeholder="Choose file"
                          @input="inputImageRenderer"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    

                  </div>
                </b-media-body>
              </b-media>
              <b-button
                      variant="primary"
                      class="m-1 mb-sm-0 mr-0 mr-sm-1"
                      @click="UpdatePhoto"
                    >
                      <span>حفظ الصورة </span>
                    </b-button>
            </div>
          </b-col>
          <!-- Field: Username -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="الاسم"
              label-for="username"
            >
              <b-form-input
                id="username"
                v-model="profileData.first_name"
                disabled
              />
            </b-form-group>
          </b-col>

          <!-- Field: Full Name -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="النسبة"
              label-for="full-name"
            >
              <b-form-input
                id="full-name"
                v-model="profileData.last_name"
                disabled
              />
            </b-form-group>
          </b-col>

          <!-- Field: Email -->

          <!-- Field: Status -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="الجنس"
              label-for="user-status"
            >
              <b-form-input
                id="usergender"
                v-model="profileData.gender.name"
                disabled
              />
            </b-form-group>
          </b-col>

          <!-- Field: Role -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="الدور"
              label-for="user-role"
            >
              <b-form-input
                id="role"
                v-model="profileData.role_id[0].name"
                disabled
              />
            </b-form-group>
          </b-col>

          <!-- Field: Birth -->
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group
              label="تاريخ الميلاد"
              label-for="birth-date"
            >
              <flat-pickr
                v-model="profileData.birth_date"
                class="form-control"
                placeholder="YYYY-MM-DD"
              />
            </b-form-group>
          </b-col>
          <!-- Field: Mobile -->
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group
              label="الرقم"
              label-for="mobile"
            >
              <b-form-input
                v-model="profileData.phone"
                disabled
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              label="email"
              label-for="email"
            >
              <b-form-input
                id="email"
                v-model="profileData.email"
                type="email"
                disabled
              />
            </b-form-group>
          </b-col>
        </b-row>
        <div class="d-flex mt-2">
          <feather-icon
            icon="MapPinIcon"
            size="19"
          />
          <label
            class="mb-0 ml-50"
            style="font-size: 15px"
          > العنوان </label>
        </div>

        <b-row class="mt-1">
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group
              label="العنوان الحالي"
              label-for="country"
            >
              <b-form-input
                id="country"
                v-model="profileData.address"
                disabled
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
        
            <b-form-group
              label=" الراتب"
              label-for="country"
            >
              <b-form-input
                v-model="profileData.salary"
                disabled
                placeholder="salary"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group
              label="تاريخ التوظيف"
              label-for="birth-date"
            >
              <flat-pickr
                v-model="profileData.hire_date"
                class="form-control"
                placeholder="YYYY-MM-DD"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <div class="d-flex mt-2">
          <feather-icon
            icon="LogInIcon"
            size="19"
          />
          <label
            class="mb-0 ml-50"
            style="font-size: 15px"
          > معلومات الدخول </label>
        </div>

        <br><br>
        <b-row>
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="اسم المستخدم"
              label-for="email"
            >
              <b-form-input
                id="email"
                v-model="profileData.username"
                type="email"
                disabled
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            md="12"
          >
            <div class="d-flex mt-2">
              <feather-icon
                icon="LogInIcon"
                size="19"
              />
              <label
                class="mb-0 ml-50"
                style="font-size: 15px"
              >  تعديل كلمة المرور </label>
            </div>
          </b-col>
          <b-col
            cols="12"
            md="12"
          >
            <validation-observer ref="simpleRules">
              <b-form>
                <b-form-group
                  label-for="كلمة المرور السابقة"
                  label="كلمة المرور"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Password"
                    vid="password"
                    rules="required"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid' : null"
                    >
                      <b-form-input
                        id="register-password2"
                        v-model="profileData.old_password"
                        class="form-control-merge"
                        :type="passwordFieldType"
                        :state="errors.length > 0 ? false : null"
                        name="register-password"
                        placeholder="············"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          :icon="passwordToggleIcon"
                          class="cursor-pointer"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group
                  label-for=" كلمة المرور الجديدة"
                  label="تأكيد كلمة المرور"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="confirmPassword"
                    vid="confirmPassword"
                    rules="required|password"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid' : null"
                    >
                      <b-form-input
                        id="register-password"
                        v-model="profileData.new_password"
                        class="form-control-merge"
                        :type="passwordFieldType"
                        :state="errors.length > 0 ? false : null"
                        name="register-password"
                        placeholder="············"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          :icon="passwordToggleIcon"
                          class="cursor-pointer"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-form>
            </validation-observer>
          </b-col>
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            @click="UpdatePassword"
          >
            <span> حفظ </span>
          </b-button>

        </b-row>
      </b-form>
      <!-- Form: Personal Info Form -->
    </b-card>

    <!-- Field: Country -->

    <!-- PERMISSION TABLE -->
    <b-card class="border mt-1">
      <b-card-header class="p-1">
        <b-card-title class="font-medium-2">
          <feather-icon
            icon="FileIcon"
            size="18"
          />
          <span class="align-middle ml-50">القسم</span>
        </b-card-title>
      </b-card-header>
      <b-row>
        <b-col
          cols="4"
          md="4"
        >
          <b-form-group
            label="رقم الموظف"
            label-for="username"
          >
            {{ profileData.employee_code }}
          </b-form-group>
        </b-col>

        <!-- Field: Status -->
        <b-col
          v-if="profileData.position"
          cols="4"
          md="4"
        >
          <b-form-group
            label="القسم"
            label-for="user-status"
          >
            {{ profileData.position.name }}
          </b-form-group>
        </b-col>

        <!-- Field: Role -->
        <!-- <b-col cols="12" md="4" xl="8">
        <b-form-group label="المسمى الوظيفي" label-for="user-role">
           <b-form-input id="userposation" />
        </b-form-group>
      </b-col> -->

        <!-- Field: Birth -->
        <b-col
          cols="4"
          md="4"
          lg="4"
          xl="8"
        >
          <b-form-group
            label="تاريخ بدء العمل"
            label-for="birth-date"
          >
            {{ profileData.hire_date }}
          </b-form-group>
        </b-col>
      </b-row>
      <b-row />
    </b-card>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import flatPickr from 'vue-flatpickr-component'
import {
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BTable,
  BCard,
  BCardHeader,
  BInputGroup,
  BCardTitle,
  BInputGroupAppend,
  BFormFile,
  BFormInvalidFeedback,
  BMediaAside,
  BImg,
  BMediaBody,
  BFormCheckbox,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import vSelect from 'vue-select'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import useUsersList from '../users-list/useUsersList'

export default {
  components: {
    flatPickr,
    BFormFile,
    required,
    BInputGroup,
    togglePasswordVisibility,
    BInputGroupAppend,
    ValidationObserver,
    ValidationProvider,
    BButton,

    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BMedia,
    BFormInvalidFeedback,
    BMediaAside,
    BImg,
    BMediaBody,
    BFormCheckbox,
    vSelect,
  },
  mixins: [togglePasswordVisibility],
  props: {
    profileData: {
      salary:'',
      new_password: '',
      old_password: '',
    },
  },
  setup(props) {
    const Tabimage = ref('media/svg/files/blank-image.svg')
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      // eslint-disable-next-line no-param-reassign
      // console.log('dff',base64)
      previewEl.value.src = base64
    })
    return {
      refInputEl,
      Tabimage,
      previewEl,
      inputImageRenderer,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  created(){
  
  },
  methods: {
    UpdatePassword() {
      return new Promise((resolve, reject) => {
        this.$refs.simpleRules.validate().then(success => {
          if (success) {
            const data = {
              new_password: this.profileData.new_password,
              old_password: this.profileData.old_password,
            }
            this.$http
              .put('/api/v1/reset_password', data)
              .then(res => {
                this.$swal({
                  title: 'تم  التعديل بنجاح',
                  icon: 'success',
                  confirmButtonText: 'موافق',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
              }).catch(error => {
                this.$swal({
                  title: '',
                  text: `${error.response.data.message}`,
                  icon: 'error',
                  confirmButtonText: 'موافق',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
              })
          }
        })
      })
    },
    UpdatePhoto() {
      const formData = new FormData()
      formData.append('profile_image', this.Tabimage)

      this.$http
        .post(`/api/v1/upload-user-profile-image/${this.profileData.id}`, formData)
        .then(res => {
          this.$swal({
            title: 'تم  التعديل بنجاح',
            icon: 'success',
            confirmButtonText: 'موافق',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        }).catch(error => {
          this.$swal({
            title: '',
            text: `${error.response.data.message}`,
            icon: 'error',
            confirmButtonText: 'موافق',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
    },

  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
