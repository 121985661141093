<template>
  <div
    v-if="Object.keys(profileData).length"
    id="user-profile"
  >
    <!-- <profile-header /> -->
    <!-- profile info  -->
    <section id="profile-info">
      <b-row>
        <!-- about suggested page and twitter feed -->
        <b-col
          lg="3"
          cols="12"
          order="2"
          order-lg="1"
        >
          <!-- <profile-about :about-data="profileData.userAbout" /> -->
          <!-- <profile-suggested-pages :pages-data="profileData.suggestedPages" />
          <profile-twitter-feed :twitter-feed="profileData.twitterFeeds" /> -->
        </b-col>
        <!--/ about suggested page and twitter feed -->

        <!-- post -->
        <b-col
          lg="6"
          cols="12"
        >
          <profile-info :profile-data="profileData" />

        </b-col>

        <!-- post -->

        <!-- latest photos suggestion and polls -->
        <b-col
          lg="6"
          cols="12"
        >
          <profile-holidays />
          <profile-rewards />
          <!-- <profile-Bank  /> -->
        </b-col>

        <b-col
          lg="4"
          cols="12"
        >  <!--/ latest photos suggestion and polls -->

        </b-col>

        <!--/ load more  -->
      </b-row>

    </section>
    <!--/ profile info  -->
  </div>
</template>

<script>
import { BRow, BCol, BButton } from 'bootstrap-vue'

import ProfileHeader from './ProfileHeader.vue'
import ProfileRewards from './ProfileRewards'

import ProfileInfo from './ProfileInfo.vue'
import ProfileHolidays from './ProfileHolidays'
import ProfileBank from './ProfileBank.vue'
import profileBottom from './profileBottom.vue'

/* eslint-disable global-require */
export default {
  components: {
    ProfileHolidays,
    BButton,
    ProfileRewards,
    BRow,
    BCol,

    ProfileHeader,

    ProfileInfo,

    ProfileBank,
    profileBottom,
  },
  data() {
    return {
      profileData: { },
    }
  },
  created() {
    let base_salary
    this.$http.get('/api/v1/get-user-salary').then(res => {
 //     console.log(res.data)

      base_salary = res.data.data.base_salary

   //   console.log(this.profileData)
    })
    this.$http.get('/api/v1/profile').then(res => {
      // console.log(res.data.data)

      this.profileData = res.data.data

      this.profileData.salary = base_salary
    })
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss" >
@import '@core/scss/vue/pages/page-profile.scss';
</style>
